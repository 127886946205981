import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/"
import SEO from "../../components/seo/"
import Crumbs from "../../components/breadcrumb/"
import BackNav from "../../components/back-nav/"

import Split from "../../components/split/"
import TextBlock from "../../components/text-block/"
import LinkBlock from "../../components/link-block/"
import LogoBlock from "../../components/logo-block/"

import { psColour, wlColour, siteColour } from "../../data/data.colours"

const WlambPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			placeholderBg: file(relativePath: { eq: "backgrounds/painesend-home.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			currentDevBg: file(relativePath: { eq: "properties/aston41/aston41-interior-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			pastDevBg: file(relativePath: { eq: "properties/the-stables/the-stables-02.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aboutBg: file(relativePath: { eq: "backgrounds/tree-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			contactBg: file(relativePath: { eq: "backgrounds/chick-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			psLogo: file(relativePath: { eq: "logos/ps-logo.svg" }) {
				publicURL
			}
			psBg: file(relativePath: { eq: "backgrounds/google-map-aerial-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Wlamb" />
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={wlColour.hex}
					link={`/`}/>
				<Split>
					<TextBlock 
						title={`W Lamb`}
						titleTag={ `h1` }
						text={`
							<p>W Lamb Ltd was established in 1942 and purchased in 1969 by Peter Vallis. Still very much under Peter's guidance, The Vallis family continue to own and run the business. The team behind W Lamb Ltd have over 50 years’ experience in the construction industry and vast knowledge of commercial builds, residential conversions and joint venture funding.</p>

<p>We have a successful track record of working in joint ventures with third parties, vendors, financiers and other property developers. We understand that for a JV to be successful it must recognise the needs of both parties and accordingly, we employ a flexible approach to such structures.</p>

<p>We are always looking for new development opportunities and are very receptive to learning of prospective residential or commercial plots for purchasing with or without planning permission.</p>
						`}
						bgColour={`blue`}/>
					<Split>
						<Split vertical={true}>
							<LinkBlock
								hex={wlColour.hex}
								link={`/w-lamb/current-developments`}
								text={`Current developments`}
								background={ data.currentDevBg.childImageSharp.fluid } 
								position={`100% 50%`} />
							<LinkBlock
								hex={wlColour.hex}
								link={`/w-lamb/past-developments`}
								text={`Past developments`}
								background={ data.pastDevBg.childImageSharp.fluid } 
								position={`10% 50%`}/>
						</Split>
						<Split vertical={true}>
							<LinkBlock
								hex={siteColour.hex}
								link={`/w-lamb/about`}
								text={`About us`}
								background={ data.aboutBg.childImageSharp.fluid } 
								position={`45% 100%`} />
							<LinkBlock
								hex={siteColour.hex}
								link={`/w-lamb/contact`}
								text={`Contact`}
								background={ data.contactBg.childImageSharp.fluid } />
							<LogoBlock 
								colour={psColour.slug}
								hex={psColour.hex}
								brand={`Painesend`}
								logo={ data.psLogo.publicURL }
								background={ data.psBg.childImageSharp.fluid }
								link={ `${process.env.GATSBY_SISTER_SITE}/painesend` }
								offsite/>	
						</Split>
					</Split>
				</Split>
			</Layout>
		</>
	)
}

export default WlambPage
