import React, { useEffect } from "react"

import { Link } from "gatsby"
import Img from "gatsby-image"
import { useSpring, animated } from 'react-spring'

import styles from "./style.module.scss"

const LinkBlock = ( props ) => {
	
	return (
		
		<Link 
			to={ props.link }
			className={ styles.linkBlock }
			bg={ props.hex }>
			<animated.div className={ `${ styles.overlay }`}>
				<h2 className={ styles.title }>{ props.text }</h2>
				{ props.subtext &&
					<h3 className={ styles.subtitle }>{ props.subtext }</h3>
				}
			</animated.div>
			<Img className={ styles.background } 
				 fluid={ props.background } 
				 imgStyle={{objectPosition: props.position }}/>
		</Link>
		
	)
}

export default LinkBlock
